<template>
  <div>
    <h4>{{ $t('PUBLIC.INQUIRY.ORG_SETUP') }}</h4>

    <v-row class="mt-4">
      <v-col md="6">

        
        <memlist-text-input
          id="num_members"
          name="num_members"
          validation_type="TEXT"
          layout="vertical"
          :title="$t('PUBLIC.INQUIRY.NUM_MEMBERS')"
          v-model="form.num_members"
          :validate="force_validate"
          :placeholder="$t('PUBLIC.INQUIRY.NUM_MEMBERS')"
          :required="true"
          :invalid_text="$t('PUBLIC.INQUIRY.NUM_MEMBERS')"
          @validated="validated"
          
          >
        </memlist-text-input>

      </v-col>
    </v-row>

    <v-row class="mt-4">
      <v-col md="6">

        <v-select
          v-model="form.org_type"
          variant="outlined"
          item-title="text"
          item-value="value"
          :items="org_types_options"
          :label="$t('PUBLIC.INQUIRY.ORG_TYPE')"
          outlined
          dense
          ></v-select>

      </v-col>
    </v-row>

    <v-row>
      <v-col md="6">
        
        <v-select
          v-model="form.org_structure"
          variant="outlined"
          item-title="text"
          item-value="value"
          :items="org_structures_options"
          :label="$t('PUBLIC.INQUIRY.ORG_STRUCTURE')"
          outlined
          dense
          ></v-select>
        
      </v-col>

      <v-col md="6" v-if="form.org_structure === 'MULTI'">
        
        <v-select
          v-model="form.num_orgs"
          variant="outlined"
          item-title="text"
          item-value="value"
          :items="num_orgs_options"
          :label="$t('PUBLIC.INQUIRY.NUM_ORGS')"
          outlined
          dense
          ></v-select>
        
      </v-col>
    </v-row>

    <v-row>
      <v-col md="6">
        
        <v-select
          v-model="form.period"
          variant="outlined"
          item-title="text"
          item-value="value"
          :items="period_options"
          :label="$t('PUBLIC.INQUIRY.PERIOD')"
          outlined
          dense
          ></v-select>
        
      </v-col>
    </v-row>

    <v-row>
      <v-col md="6">
        

        <v-select
          v-model="form.has_payment"
          variant="outlined"
          item-title="text"
          item-value="value"
          :items="yes_no_options"
          :label="$t('PUBLIC.INQUIRY.HAS_PAYMENT')"
          outlined
          dense
          ></v-select>
        
      </v-col>
    </v-row>


    <v-row v-if="form.has_payment">
      <v-col md="6">

        <h4>{{ $t('PUBLIC.INQUIRY.SELECT_PAYMENT_METHODS') }}</h4>
      
        <v-checkbox value="SWISH" v-model="form.payment_methods" :label="$t('PUBLIC.INQUIRY.PAYMENT_METHODS.SWISH')"></v-checkbox>
        <v-checkbox value="STRIPE" v-model="form.payment_methods" :label="$t('PUBLIC.INQUIRY.PAYMENT_METHODS.CREDITCARD')"></v-checkbox>
        <v-checkbox value="EMAIL_INVOICE" v-model="form.payment_methods" :label="$t('PUBLIC.INQUIRY.PAYMENT_METHODS.EMAIL_INVOICE')"></v-checkbox>
        <v-checkbox value="POST_INVOICE" v-model="form.payment_methods" :label="$t('PUBLIC.INQUIRY.PAYMENT_METHODS.POST_INVOICE')"></v-checkbox>
        <v-checkbox value="OTHER" v-model="form.payment_methods" :label="$t('PUBLIC.INQUIRY.PAYMENT_METHODS.OTHER')"></v-checkbox>
        
      </v-col>
    </v-row>


    <p v-if="show_error" class="mt-8" style="color: #fd397a;">{{ $t('PUBLIC.INQUIRY.MISSING_FIELDS') }}</p>

    <div class="d-sm-flex justify-content-end align-items-center align-bottom w-100">
      <b-button
        class="mt-4 mr-4"
        variant="outline-secondary"
        @click.prevent="on_back"
      >
        {{ $t('COMMON.BACK') }}
      </b-button>
      
      <b-button
        class="mt-4"
        variant="primary"
        :type="type"
        :disabled="disabled || spinning"
        @click.prevent="on_click"
      >
        <b-spinner v-if="spinning" small type="grow"></b-spinner>
        {{ $t('COMMON.CONTINUE') }}
      </b-button>
    </div>

  </div>
</template>

<script>
import dayjs from 'dayjs';
import axios from 'axios';

import formatPersonnr, { validate_personnr } from '@/core/services/personnr';
import { isPersonnrValid } from '@/core/services/validators';
import { requiredUnless } from 'vuelidate/lib/validators';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';
import { email, minLength, maxLength } from '@vuelidate/validators';

export default {
  name: 'InquiryCompanyFormStep2',
  mixins: [ toasts ],

  data() {
    return {
      form: {
        payment_methods: []
      },
      force_validate: 0,
      is_form_valid: false,
      show_error: false,
      spinning: false,
      disabled: false,
      type: 'primary'
    };
  },
  mounted() {
    
  },

  computed: {
    yes_no_options() {
      return [
        { text: this.$t('COMMON.YES'), value: true },
        { text: this.$t('COMMON.NO'), value: false },
      ]
    },
    period_options() {
      return [
        { text: this.$t('PUBLIC.INQUIRY.PERIODS.INFINITE'), value: 'INFINITE' },
        { text: this.$t('PUBLIC.INQUIRY.PERIODS.PERIOD'), value: 'PERIOD' },
        { text: this.$t('PUBLIC.INQUIRY.PERIODS.OTHER'), value: 'OTHER' }
      ]
    },
    num_orgs_options() {
      return [
        { text: '1-5', value: '1-5' },
        { text: '6-10', value: '6-10' },
        { text: '11-20', value: '11-20' },
        { text: '21-40', value: '21-40' },
        { text: '40 <', value: '40 <' },
      ]
    },
    org_types_options() {

      var options = [
        { text: this.$t('PUBLIC.INQUIRY.ORG_TYPES.MUCF'), value: 'MUCF' },
        { text: this.$t('PUBLIC.INQUIRY.ORG_TYPES.FAITH'), value: 'FAITH' },
        { text: this.$t('PUBLIC.INQUIRY.ORG_TYPES.RIKS'), value: 'RIKS' },
        { text: this.$t('PUBLIC.INQUIRY.ORG_TYPES.COMPANY'), value: 'COMPANY' },
        { text: this.$t('PUBLIC.INQUIRY.ORG_TYPES.STUDENT'), value: 'STUDENT' },
        { text: this.$t('PUBLIC.INQUIRY.ORG_TYPES.STUDENT_COMPANY'), value: 'STUDENT_COMPANY' },
        { text: this.$t('PUBLIC.INQUIRY.ORG_TYPES.SPORTCLUB'), value: 'SPORTCLUB' },
        { text: this.$t('PUBLIC.INQUIRY.ORG_TYPES.INTEREST_CLUB'), value: 'INTEREST_CLUB' },
        { text: this.$t('PUBLIC.INQUIRY.ORG_TYPES.UNION'), value: 'UNION' },
        { text: this.$t('PUBLIC.INQUIRY.ORG_TYPES.COOP'), value: 'COOP' },
        { text: this.$t('PUBLIC.INQUIRY.ORG_TYPES.BRF'), value: 'BRF' },
        { text: this.$t('PUBLIC.INQUIRY.ORG_TYPES.STIFT'), value: 'STIFT' },
        { text: this.$t('PUBLIC.INQUIRY.ORG_TYPES.OTHER'), value: 'OTHER' }
        
      ];

      // sort by text

      options.sort((a, b) => {
        if (a.text < b.text) {
          return -1;
        }
        if (a.text > b.text) {
          return 1;
        }
        return 0;
      });

      return options;
    },
    org_structures_options() {
      return [
        { text: this.$t('PUBLIC.INQUIRY.ORG_STRUCTURES.SINGLE'), value: 'SINGLE' },
        { text: this.$t('PUBLIC.INQUIRY.ORG_STRUCTURES.MULTI'), value: 'MULTI' }
      ]
    }
  },


  watch: {
    
  },
  methods: {
    validated(field_name, value, valid) {
      this.form[field_name] = value;

      if (!valid) {
        this.is_form_valid = false;
      }
    },

    on_back() {
      this.$emit('back');
    },

    async on_click() {
      this.spinning = true;
      this.show_error = false;

      this.is_form_valid = true;
      this.force_validate = this.force_validate + 1;

      this.$nextTick(()=>{
        window.scrollTo(0,0);
        
        if (this.is_form_valid) {
          this.spinning = false;

          this.$emit('next', this.form, 1);
        }
        else {
          this.show_error = true; 

          setTimeout(()=>{
            this.spinning = false;
          }, 2000);
        }
      });
    },

  }
};
</script>
